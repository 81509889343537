<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 측정년도 -->
          <c-datepicker
            name="measYear"
            type="year"
            :range="true"
            label="측정년도"
            defaultStart="-3y"
            defaultEnd="0y"
            v-model="year"
          ></c-datepicker>
        </div> 
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="WORK_MEAS_QUARTER_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="measQuarter"
            label="분기"
            v-model="searchParam.measQuarter"
          ></c-select>
        </div>
          <!-- 측정기간 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            name="period"
            label="측정기간"
            v-model="period"
          ></c-datepicker>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="completeFlagItems"
            itemText="codeName"
            itemValue="code"
            label="진행상태"
            name="measCompleteFlag"
            v-model="searchParam.measCompleteFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업환경측정결과 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-measurement-result',
  props: {
    // count: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'measYear',
            field: 'measYear',
            label: '측정년도',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'measPlanContent',
            field: 'measPlanContent',
            label: '측정계획명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'measQuarterName',
            field: 'measQuarterName',
            label: '분기',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'measDate',
            field: 'measDate',
            // 측정일(원본)
            label: '측정기간',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'measPlaceName',
            field: 'measPlaceName',
            label: '측정기관',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'measCompleteFlagName',
            field: 'measCompleteFlagName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      year: ['', ''],
      period: [ '', ''],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: '',
        measQuarter: null,
        measYear: '',
        startYmd: '',
        endYmd: '',
        measCompleteFlag: null,
      },
      completeFlagItems: [
        { code: null, codeName: '전체' },
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '완료' },
      ],
    };
  },
  watch: {
    // 'count'() {
    //   this.getList();
    // }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.workmeas.plan.list.url;
      
      // this.year = [this.$comm.getPrevYear() - 3, this.$comm.getThisYear()];
      this.getList();
    },
    getList() {
      if (this.year && this.year.length > 0) {
        this.searchParam.startYmd = this.year[0];
        this.searchParam.endYmd = this.year[1];
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPopup(row);
    },
    openPopup(result) {
      this.popupOptions.target = () => import(`${"./workMeasurePlanDetail.vue"}`);
      this.popupOptions.title = '작업환경측정 상세';
      this.popupOptions.param = {
        workMeasurementPlanId: result ? result.workMeasurementPlanId : '',
      };
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
